import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import {Link, useTranslation, useI18next, Trans} from 'gatsby-plugin-react-i18next';
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import RocketLogo from "../components/rocket-logo"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout lightBackground>
       <SEO title={t('not-found')} />
      <div className="container w-5/6 mx-auto pb-10"> 
              <div className="flex flex-row mt-32 w-fit 2xl:mt-28 mx-auto">
                  <PageHeader className="place-self-center">
                    {t('not-found')}
                  </PageHeader>
                  <RocketLogo />
              </div>
             
        </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
